import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSelect } from 'vuetify/lib/components/VSelect';
import { VTextField } from 'vuetify/lib/components/VTextField';
import { VToolbar } from 'vuetify/lib/components/VToolbar';
import { VToolbarTitle } from 'vuetify/lib/components/VToolbar';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VContainer,[_c(VToolbar,{staticClass:"mb-2 indigo white--text",attrs:{"dense":"","flat":""}},[_c(VToolbarTitle,[_vm._v("Identifying Data")])],1),_c(VRow,{attrs:{"dense":""}},[_c(VCol,{attrs:{"cols":12}},[_c(VTextField,{attrs:{"label":"Full Name"},model:{value:(_vm.name),callback:function ($$v) {_vm.name=$$v},expression:"name"}})],1),_c(VCol,{attrs:{"cols":12}},[_c('ValidationProvider',{attrs:{"mode":"aggressive","rules":"requiredAge|ageNumeric","debounce":300},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c(VTextField,{attrs:{"label":"Age","persistent-hint":"","error-messages":errors[0]},model:{value:(_vm.ageDob),callback:function ($$v) {_vm.ageDob=$$v},expression:"ageDob"}})]}}])})],1),_c(VCol,{attrs:{"cols":12}},[_c('ValidationProvider',{attrs:{"mode":"aggressive","rules":"requiredSex","debounce":300},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c(VSelect,{attrs:{"items":_vm.genders,"label":"Sex","persistent-hint":"","error-messages":errors[0]},model:{value:(_vm.sex),callback:function ($$v) {_vm.sex=$$v},expression:"sex"}})]}}])})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }